// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _some from 'lodash/some';
import _isEmpty from 'lodash/isEmpty';

import * as pth from '../../../helpers/proptypes';

import Banner from '../../presentationals/modules/banner';
import Benefits from '../../presentationals/modules/benefits';
import BenefitsAlt from '../../presentationals/modules/benefitsAlt';
import BigCover from '../../presentationals/modules/bigcover';
import Button from '../../presentationals/modules/button';
import Clients from '../../presentationals/modules/clients';
import ContactForm from '../../containers/modules/contactForm';
import ContactUs from '../../presentationals/modules/contactUs';
import DoubleEntity from '../../presentationals/modules/doubleEntity';
import CallToAction from '../../presentationals/modules/callToAction';
import Contact from '../../presentationals/modules/contact';
import FullSizeCover from '../../containers/modules/fullSizeCover';
import Image from '../../presentationals/modules/image';
import Links from '../../presentationals/modules/links';
import Masonry from '../../presentationals/modules/masonry';
import MasonryUserPlaylists from '../../presentationals/modules/masonryUserPlaylists';
import MasonryVideo from '../../containers/modules/masonryVideo';
import Newsletter from '../../containers/modules/newsletter';
import Partners from '../../presentationals/modules/partners';
import Post from '../../presentationals/modules/post';
import SimpleBenefits from '../../presentationals/modules/simpleBenefits';
import SingleEntity from '../../presentationals/modules/singleEntity';
import Slider from '../../presentationals/modules/slider';
import SliderVideo from '../../containers/modules/sliderVideo';
import Social from '../../presentationals/modules/social';
import Text from '../../presentationals/modules/text';
import TextImage from '../../presentationals/modules/textImage';
import TitleDescLink from '../../presentationals/modules/titleDescLink';
import Tracks from '../../presentationals/modules/tracks';
import TracksFavorites from '../../presentationals/modules/tracksFavorites';
import TracksHistory from '../../presentationals/modules/tracksHistory';
import TrendingWithTracks from '../../presentationals/modules/trendingWithTracks';

import PageWrapper from '../../other/pagewrapper';

// =============================
// Stories
// =============================

class CustomPage extends Component {
  static propTypes = {
    data: pth.configPage.isRequired,
    withMailchimp: PropTypes.bool.isRequired,
  };

  renderAddressesModule = module => (
    <Contact
      colors={module.colors}
      doc={module.data}
      id={module.id}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderBannerModule = module => (
    <Banner
      colors={module.colors}
      data={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderBenefitsModule = module => (
    <Benefits
      colors={module.colors}
      data={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderBenefitsAltModule = module => (
    <BenefitsAlt
      colors={module.colors}
      data={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderBigCoverModule = (module) => {
    const { data } = this.props;
    const { modules } = data;

    return (
      <BigCover
        colors={module.colors}
        doc={{
          ...module.data,
          type: module.type,
        }}
        isModuleAlone={modules.length === 1}
        key={module.id}
        spacings={{
          margin: module.margin,
          padding: module.padding,
        }}
        type={module.type}
      />
    );
  };

  renderButtonModule = module => (
    <Button
      colors={module.colors}
      data={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderCallToActionModule = module => (
    <CallToAction
      colors={module.colors}
      data={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderClientsModule = module => (
    <Clients
      colors={module.colors}
      data={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderContactFormModule = module => (
    <ContactForm
      colors={module.colors}
      data={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderContactUsModule = module => (
    <ContactUs
      colors={module.colors}
      data={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderEntitiesModule = (module) => {
    const { data } = module;

    if (data.length === 1) {
      const doc = {
        ...data[0].data,
        type: data[0].type,
        ...(!_isEmpty(data[0].descriptions) ? { descriptions: data[0].descriptions } : {}),
      };

      return (
        <SingleEntity
          doc={doc}
          colors={module.colors}
          key={module.id}
          spacings={{
            margin: module.margin,
            padding: module.padding,
          }}
        />
      );
    }

    return (
      <DoubleEntity
        colors={module.colors}
        docs={data}
        key={module.id}
        spacings={{
          margin: module.margin,
          padding: module.padding,
        }}
      />
    );
  };

  renderFullSizeCoverModule = module => (
    <FullSizeCover
      colors={module.colors}
      data={{
        ...module.data,
        id: module.id,
      }}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderImageModule = module => (
    <Image
      alt=""
      colors={module.colors}
      data={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderLinksModule = module => (
    <Links
      colors={module.colors}
      data={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderMasonryModule = module => (
    <Masonry
      colors={module.colors}
      data={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderMasonryUserPlaylistsModule = module => (
    <MasonryUserPlaylists
      colors={module.colors}
      data={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderMasonryVideoModule = module => (
    <MasonryVideo
      colors={module.colors}
      data={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderNewsletterModule = module => (
    <Newsletter
      colors={module.colors}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderPartnersModule = module => (
    <Partners
      colors={module.colors}
      data={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderPostModule = module => (
    <Post
      colors={module.colors}
      doc={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderSimpleBenefitsModule = module => (
    <SimpleBenefits
      colors={module.colors}
      data={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderSliderModule = module => (
    <Slider
      colors={module.colors}
      data={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderSliderVideoModule = module => (
    <SliderVideo
      colors={module.colors}
      data={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderSocialModule = module => (
    <Social
      colors={module.colors}
      data={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderTextModule = module => (
    <Text
      colors={module.colors}
      doc={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderTextImageModule = module => (
    <TextImage
      colors={module.colors}
      data={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderTitleDescLinkModule = module => (
    <TitleDescLink
      colors={module.colors}
      data={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderTracksModule = module => (
    <Tracks
      colors={module.colors}
      data={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderTracksFavoritesModule = module => (
    <TracksFavorites
      colors={module.colors}
      data={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderTracksHistoryModule = module => (
    <TracksHistory
      colors={module.colors}
      data={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderTrendingWithTracksModule = module => (
    <TrendingWithTracks
      colors={module.colors}
      data={module.data}
      key={module.id}
      spacings={{
        margin: module.margin,
        padding: module.padding,
      }}
    />
  );

  renderModule = (module) => {
    const { type } = module;

    switch (type) {
      case 'addresses':
        return this.renderAddressesModule(module);
      case 'banner':
        return this.renderBannerModule(module);
      case 'benefits':
        return this.renderBenefitsModule(module);
      case 'benefits_alt':
        return this.renderBenefitsAltModule(module);
      case 'button':
        return this.renderButtonModule(module);
      case 'call_to_action':
        return this.renderCallToActionModule(module);
      case 'clients':
        return this.renderClientsModule(module);
      case 'contact_form':
        return this.renderContactFormModule(module);
      case 'contact_us':
        return this.renderContactUsModule(module);
      case 'entities':
        return this.renderEntitiesModule(module);
      case 'full_size_cover':
        return this.renderFullSizeCoverModule(module);
      case 'image':
        return this.renderImageModule(module);
      case 'links':
        return this.renderLinksModule(module);
      case 'masonry':
        return this.renderMasonryModule(module);
      case 'masonry_user_playlists':
        return this.renderMasonryUserPlaylistsModule(module);
      case 'masonry_video':
        return this.renderMasonryVideoModule(module);
      case 'newsletter':
        return this.renderNewsletterModule(module);
      case 'partners':
        return this.renderPartnersModule(module);
      case 'post':
        return this.renderPostModule(module);
      case 'simple_benefits':
        return this.renderSimpleBenefitsModule(module);
      case 'slider':
        return this.renderSliderModule(module);
      case 'slider_video':
        return this.renderSliderVideoModule(module);
      case 'social':
        return this.renderSocialModule(module);
      case 'text':
        return this.renderTextModule(module);
      case 'text_image':
        return this.renderTextImageModule(module);
      case 'title_desc_link':
        return this.renderTitleDescLinkModule(module);
      case 'tracks':
        return this.renderTracksModule(module);
      case 'tracks_favorites':
        return this.renderTracksFavoritesModule(module);
      case 'tracks_history':
        return this.renderTracksHistoryModule(module);
      case 'trending_with_tracks':
        return this.renderTrendingWithTracksModule(module);
      default:
        return null;
    }
  };

  shouldRenderBigCover = (module) => {
    if (module.type === 'post') return true;

    if (module.type === 'entities') {
      if (module.data.length === 1) {
        return true;
      }
    }

    return false;
  };

  shouldRenderModule = (module) => {
    const { withMailchimp } = this.props;

    switch (module.type) {
      case 'addresses':
        return _some([
          !_isEmpty(_get(module, 'data.contacts')),
          _get(module, 'data.googlePlaceId'),
          _get(module, 'data.contactImage.original.url'),
        ], e => !!e);

      case 'banner':
        return _some([
          !_isEmpty(_get(module, 'data.title')),
          !_isEmpty(_get(module, 'data.breadcrumbs')),
          _get(module, 'data.image.original.url'),
          _get(module, 'data.imageMobile.original.url'),
        ], e => !!e);

      case 'call_to_action':
        // linkText on its own is not rendered
        // outerBackground color on their own is useless
        return _some([
          !_isEmpty(_get(module, 'data.title')),
          !_isEmpty(_get(module, 'data.benefits')),
          _get(module, 'data.linkUrl'),
          _get(module, 'data.image.original.url'),
        ], e => !!e);

      case 'contact_form':
        // The only required value to make the form work is the email
        return !!_get(module, 'data.contact.email');

      case 'full_size_cover':
        // videoRatioX & videoRatioY without video is useless
        // displayScrollIndicator on its own is useless
        // displaySearchBar on its own is useless
        // displayVideoLink without video is useless
        return _some([
          !_isEmpty(_get(module, 'data.title')),
          _get(module, 'data.videoLink'),
          _get(module, 'data.image.original.url'),
          _get(module, 'data.imageMobile.original.url'),
        ], e => !!e);

      case 'image':
        // Without image, module is useless
        return _some([
          _get(module, 'data.image.original.url'),
          _get(module, 'data.imageMobile.original.url'),
        ], e => !!e);

      case 'newsletter': return withMailchimp;

      case 'tracks_favorites':
      case 'tracks_history':
      case 'masonry_user_playlists':
        return true;

      case 'post':
        return _some([
          !_isEmpty(_get(module, 'data.title')),
          !_isEmpty(_get(module, 'data.claim')),
          !_isEmpty(_get(module, 'data.descriptions')),
          _get(module, 'data.linkUrl'),
          _get(module, 'data.coverImage.original.url'),
        ], e => !!e);

      case 'social':
        return _some([
          _get(module, 'data.social.applemusic'),
          _get(module, 'data.social.deezer'),
          _get(module, 'data.social.facebook'),
          _get(module, 'data.social.instagram'),
          _get(module, 'data.social.linkedin'),
          _get(module, 'data.social.spotify'),
          _get(module, 'data.social.twitter'),
          _get(module, 'data.social.youtube'),
        ], e => !!e);

      case 'text_image':
        // linkText on its own is not rendered
        // linkTextual on its own is useless
        return _some([
          !_isEmpty(_get(module, 'data.title')),
          !_isEmpty(_get(module, 'data.descriptions')),
          !_isEmpty(_get(module, 'data.claim')),
          _get(module, 'data.linkUrl'),
          _get(module, 'data.image.original.url'),
          _get(module, 'data.imageMobile.original.url'),
        ], e => !!e);

      case 'title_desc_link':
        // linkText on its own is not rendered
        // linkPlacement on its own is useless
        return _some([
          !_isEmpty(_get(module, 'data.title')),
          !_isEmpty(_get(module, 'data.descriptions')),
          _get(module, 'data.linkUrl'),
          _get(module, 'data.image.original.url'),
        ], e => !!e);

      case 'clients':
      case 'masonry':
      case 'slider':
        // Do not render if there is no items data
        return !_isEmpty(_get(module, 'data.items'));

      // benefits - Empty array
      // benefits_alt - Empty array
      // button - Undefined
      // contact_us - Undefined
      // entities - Empty array
      // links - Empty array
      // masonry_video - Empty array
      // partners - Empty array
      // simple_benefits - Empty array
      // slider_video - Empty array
      // text - Empty array
      // tracks - Empty array
      // trending_with_tracks - Empty array
      default:
        // Do not render if there is no data
        return !_isEmpty(module.data);
    }
  };

  render() {
    const { data } = this.props;
    const { modules = [] } = data;

    const noPaddingTop = (
      !modules
      || !modules.length
      || ['addresses', 'full_size_cover', 'post'].includes(modules[0].type)
      || (modules[0].type === 'entities' && modules[0].data.length === 1)
    );

    return (
      <PageWrapper noPaddingTop={noPaddingTop} type="customPage">
        {modules.map((module, i) => {
          if (!this.shouldRenderModule(module)) return null;
          if (i === 0 && this.shouldRenderBigCover(module)) {
            return this.renderBigCoverModule(module);
          }
          return this.renderModule(module);
        })}
      </PageWrapper>
    );
  }
}

export default CustomPage;
